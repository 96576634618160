import React from "react";

interface Props {
  className: any;
}

export const Logo = ({ className }: Props): JSX.Element => {
  return (
    <div className={`w-[300px] h-[300px] bg-[url(https://dyatkw7msrzcb.cloudfront.net/assets/image-1.png)] bg-cover bg-[50%_50%] ${className}`} />
  );
};
